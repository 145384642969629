import { createSlice } from '@reduxjs/toolkit'

export const userAlertsSlice = createSlice({
  name: 'userAlerts',
  initialState: {
    value: []
  },
  reducers: {
    setUserAlerts: (state, action) => {
        state.value = action.payload
    },
    
  },
})

export const { setUserAlerts } = userAlertsSlice.actions


export default userAlertsSlice.reducer