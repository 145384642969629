import { createSlice } from '@reduxjs/toolkit'

export const accessPolicySlice = createSlice({
  name: 'accessPolicy',
  initialState: {
    value: [],
  },
  reducers: {
    setAccessPolicy: (state, action) => {
        state.value = action.payload
    },
  },
})


export const { setAccessPolicy } = accessPolicySlice.actions

export default accessPolicySlice.reducer