import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { setSites } from '../features/sitesSlice'
import { setIdpToken } from '../features/idpTokenSlice'
import { setAccessPolicy } from '../features/accessPolicySlice'
import { setUser } from '../features/userSlice'
import { useCookies } from 'react-cookie';
import './UserDropdown.scss';

const UserDropdown = (props) => {
  const { t, i18n } = useTranslation('menu');
  const displayName = props.displayName;
  const firstLetter = props.displayName?.charAt(0);
  const expires = props.expires;

  const dispatch = useDispatch()

  const navigate = useNavigate();


  const [idpTokenCookie, setIdpTokenCookie] = useCookies(['idpToken']);

  const deconnexion = () => {
      setIdpTokenCookie('idpToken', '', { path: '/' });
      dispatch(setIdpToken({}));
      dispatch(setUser({}));
      dispatch(setAccessPolicy({}));
      dispatch(setSites({}));
      navigate("/login")
  }
  
  return (
      <div className="dropdown user-dropdown">
          <a href="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-offset="0,8">
              <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.9 19.2754C20.747 22.431 17.1163 24.4996 13.0002 24.4996C8.88408 24.4996 5.25345 22.431 3.1004 19.2754C5.25345 16.1197 8.88408 14.0511 13.0002 14.0511C17.1163 14.0511 20.747 16.1197 22.9 19.2754Z" stroke="#929292" strokeMiterlimit="10"/>
              <path d="M12.9999 0.500366C6.37189 0.500366 0.999817 5.87235 0.999817 12.5003C0.999817 15.0121 1.77027 17.3445 3.1001 19.2759C5.25314 16.1203 8.88378 14.0517 12.9999 14.0517C17.116 14.0517 20.7467 16.1203 22.8997 19.2759C24.2295 17.3445 25 15.0121 25 12.5003C25 5.87235 19.6279 0.500366 12.9999 0.500366ZM12.9999 12.1309C10.5408 12.1309 8.54604 10.1467 8.54604 7.68764C8.54604 5.22856 10.5408 3.23385 12.9999 3.23385C15.459 3.23385 17.4432 5.22856 17.4432 7.68764C17.4432 10.1467 15.459 12.1309 12.9999 12.1309Z" stroke="#929292" strokeMiterlimit="10"/>
              <path d="M17.4438 7.68657C17.4438 10.1457 15.4597 12.1298 13.0005 12.1298C10.5414 12.1298 8.54669 10.1457 8.54669 7.68657C8.54669 5.22749 10.5414 3.23279 13.0005 3.23279C15.4597 3.23279 17.4438 5.22749 17.4438 7.68657Z" stroke="#929292" strokeMiterlimit="10"/>
              </svg>

              <strong className="user-dropdown__name">{displayName}</strong>
          </a>
          <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
              <li><Link to="/privacy-policy" className="dropdown-item">{t('privacyPolicy', { ns: 'menu' })}</Link></li>
              <li><Link to="/terms-of-use" className="dropdown-item">{t('termsOfUse', { ns: 'menu' })}</Link></li>
              <li><Link to="/settings/general" className="dropdown-item">{t('settings', { ns: 'menu' })}</Link></li>
              <li><hr className="dropdown-divider" /></li>
              <li><Link to="/login" onClick={() => deconnexion()} className="dropdown-item">{t('signOut', { ns: 'menu' })}</Link></li>
          </ul>
      </div>
  )
}

export default UserDropdown;