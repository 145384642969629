import { createSlice } from '@reduxjs/toolkit'

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
}

const sortByTenantNameAndName = ( a, b ) => {

  const ta = a.tenant.name.toLowerCase();
  const tb = b.tenant.name.toLowerCase();

  const na = a.name.toLowerCase();
  const nb = b.name.toLowerCase();

  if ( ta < tb || na < nb ){
      return -1;
  }
  if ( ta > tb || na > nb ){
      return 1;
  }
  return 0;
}

export const sitesSlice = createSlice({
  name: 'sites',
  initialState: {
    value: [],
  },
  reducers: {
    setSites: (state, action) => {
      let sites = [];
      const accessPolicy = action.payload;

      accessPolicy.map((statement, indexStatement) => {

        statement.Tenant.Sites.map((site, indexSite) => {

          const indexTemp = sites.findIndex((temp, index) => {
            if(temp.uuid == site.UUID)
              return true;
          })

          if (indexTemp === -1){
            sites.push({
              uuid:site.UUID, 
              name:site.Name,
              tenant: {
                name:statement.Tenant.LegalName,
                uuid:statement.Tenant.UUID
              },
              actions:statement.Actions
            })
          } else {
            sites[indexTemp].actions = sites[indexTemp].actions.concat(statement.Actions).filter(onlyUnique)
          }

        });
      });


      sites.sort(function (a, b) {
        return a.tenant.name.localeCompare(b.tenant.name) || a.name.localeCompare(b.name);
      });

      state.value = sites
    },
    
  },
})


export const { setSites } = sitesSlice.actions

export default sitesSlice.reducer