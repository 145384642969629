import { createSlice } from '@reduxjs/toolkit'

export const idpTokenSlice = createSlice({
  name: 'idpToken',
  initialState: {
    value: {},
  },
  reducers: {
    setIdpToken: (state, action) => {
        state.value = action.payload
    },
  },
})


export const { setIdpToken } = idpTokenSlice.actions

export default idpTokenSlice.reducer