// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".offline-footer{display:flex;flex-wrap:wrap;justify-content:center;margin:auto 0 0;padding:.25rem;text-shadow:0 0 .25rem #000;width:100%}.offline-footer__link{margin:0 .5rem}.offline-footer__link:hover{text-decoration:underline}.offline-footer__copyright{font-size:.75rem;padding-top:1rem;text-align:center;width:100%}", "",{"version":3,"sources":["webpack://./src/components/OfflineFooter.scss"],"names":[],"mappings":"AAEA,gBACI,YAAA,CAMA,cAAA,CAHA,sBAAA,CAFA,eAAA,CAGA,cAAA,CACA,2BAAA,CAHA,UAIA,CAEA,sBACI,cAAA,CAEA,4BACI,yBAAA,CAIR,2BAGI,gBAAA,CACA,gBAAA,CAHA,iBAAA,CACA,UAEA","sourcesContent":["@import \"../variables\";\r\n\r\n.offline-footer {\r\n    display: flex;\r\n    margin: auto 0 0 0;\r\n    width: 100%;\r\n    justify-content: center;\r\n    padding: .25rem;\r\n    text-shadow: 0 0 0.25rem rgba(0, 0, 0, 1);\r\n    flex-wrap: wrap;\r\n\r\n    &__link {\r\n        margin: 0 0.5rem;\r\n\r\n        &:hover {\r\n            text-decoration: underline;\r\n        }\r\n    }\r\n\r\n    &__copyright {\r\n        text-align: center;\r\n        width: 100%;\r\n        font-size: 0.75rem;\r\n        padding-top: 1rem;\r\n    }\r\n    \r\n    \r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
export var offlineFooter = "offline-footer";
export var offlineFooterLink = "offline-footer__link";
export var offlineFooterCopyright = "offline-footer__copyright";
export default ___CSS_LOADER_EXPORT___;
