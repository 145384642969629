
import ReactGA from "react-ga4";
import {
    GA_MEASUREMENT_ID
} from '../const';



export const analyticsInit = (user) => {
    
    if(user.full_name){
        ReactGA.initialize(
            GA_MEASUREMENT_ID,
            {
                gaOptions: {
                'name': user.full_name,
                'userId': user.uuid
                }
            }
        );
    }

}

export const analyticsEventSite = (siteData, user, page) => {
    
    ReactGA.event({
        category: page,
        action: user.full_name+" "+page+" "+siteData.name,
        //label: "your label", // optional
        //value: 99, // optional, must be a number
        //nonInteraction: true, // optional, true/false
        //transport: "xhr", // optional, beacon/xhr/image
    });
}

export const analyticsEventPage = (page) => {
    
}

export const analyticsEventLogin = (user) => {
    
    if(user.full_name){
        analyticsInit(user);

        ReactGA.event({
            category: "Login",
            action: user.full_name+" logged in",
            //label: "your label", // optional
            //value: 99, // optional, must be a number
            //nonInteraction: true, // optional, true/false
            //transport: "xhr", // optional, beacon/xhr/image
        });
    }
    
}

export const analyticsPageView = () => {
    ReactGA.send("pageview");
}