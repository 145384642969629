import { configureStore } from '@reduxjs/toolkit'
import fleetAlertsReducer from './features/fleetAlertsSlice'
import slidesReducer from './features/sitesSlice'
import idpTokenReducer from './features/idpTokenSlice'
import adminTokenReducer from './features/adminTokenSlice'
import userReducer from './features/userSlice'
import accessPolicyReducer from './features/accessPolicySlice'
import settingsReducer from './features/settingsSlice'
import userAlertsReducer from './features/userAlertsSlice'

export default configureStore({
  reducer: {
    sites: slidesReducer,
    fleetAlerts: fleetAlertsReducer,
    idpToken:idpTokenReducer,
    adminToken:adminTokenReducer,
    user:userReducer,
    accessPolicy:accessPolicyReducer,
    settings:settingsReducer,
    userAlerts: userAlertsReducer,
  },
})