import React from 'react';


import { useTranslation } from 'react-i18next';
import { Link, useParams, NavLink, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie';

import UserDropdown from './UserDropdown';
import SiteDropdown from './SiteDropdown';
import UserAlertsDropdown from './UserAlertsDropdown'

import { VIEW_NOTIFICATION } from '../const';

import './MenuHeader.scss';



const MenuHeader = (props) => {
    const { t, i18n } = useTranslation('menu');
    

    const dispatch = useDispatch()

    const navigate = useNavigate();
    const params = useParams();
    const sites = useSelector((state) => state.sites.value)
    const idpToken = useSelector((state) => state.idpToken.value)
    const user = useSelector((state) => state.user.value)
    const [siteId, setSiteId] = useState('');
    const [idpTokenCookie, setIdpTokenCookie] = useCookies(['idpToken']);

    let viewNotification = false
    sites.forEach(site => {
        if (site.actions.indexOf(VIEW_NOTIFICATION) !== -1) {
            viewNotification = true
        }
    });

    useEffect(() => {
        
        if(params.id){
            
            setSiteId(params.id)
        }else {
            setSiteId('')
        }
    }, [params]);
  
    return (
      <div className='menu-header'>
        <div className='menu-header__left'>
            <SiteDropdown/>
        </div>
        <div className='menu-header__right'>
            {/*<FleetAlerts siteId={siteId} />*/}
            {viewNotification &&
                <UserAlertsDropdown />
            }
            <UserDropdown displayName={user.given_name} expires={idpToken.expires} />
        </div>
      </div>

    )
}

export default MenuHeader;