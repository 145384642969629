// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inline-error{display:flex;padding:2rem 0}.inline-error__container{align-items:center;display:flex;flex-direction:column;justify-content:center;max-width:15rem;text-align:center}.inline-error__title svg{height:1.5rem;margin:-.35rem .5rem 0 0;width:1.5rem}.inline-error__btn svg{fill:currentColor;margin-right:.35rem;margin-top:-.3rem}", "",{"version":3,"sources":["webpack://./src/components/ErrorInline.scss"],"names":[],"mappings":"AAEA,cACE,YAAA,CACA,cAAA,CAEA,yBAIE,kBAAA,CAFA,YAAA,CAGA,qBAAA,CAFA,sBAAA,CAFA,eAAA,CAKA,iBAAA,CAID,yBAEC,aAAA,CACA,wBAAA,CAFA,YAEA,CAKA,uBAGE,iBAAA,CAFA,mBAAA,CACA,iBACA","sourcesContent":["@import \"../variables\";\r\n\r\n.inline-error {\r\n  display: flex;\r\n  padding: 2rem 0;\r\n\r\n  &__container {\r\n    max-width: 15rem;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-direction: column;\r\n    text-align: center;\r\n  }\r\n\r\n  &__title {\r\n   svg {\r\n    width: 1.50rem;\r\n    height: 1.50rem;\r\n    margin: -0.35rem 0.5rem 0 0;\r\n   } \r\n  }\r\n\r\n  &__btn {\r\n    svg {\r\n      margin-right: 0.35rem;\r\n      margin-top: -0.30rem;\r\n      fill: currentColor;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var inlineError = "inline-error";
export var inlineErrorContainer = "inline-error__container";
export var inlineErrorTitle = "inline-error__title";
export var inlineErrorBtn = "inline-error__btn";
export default ___CSS_LOADER_EXPORT___;
