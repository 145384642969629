import React, { lazy, Suspense } from 'react';
import { Routes, BrowserRouter as Router, Route, useNavigate, useParams } from 'react-router-dom';


import { useState, useEffect } from "react";

import { useSelector, useDispatch } from 'react-redux'

import { analyticsInit } from '../features/analyticsLogging';


import { 
  hotjatInit, 
  hotjatIdentifyUser 
} from '../features/hotjarLogging';

import Home from './Home';
import Loader from './Loader';
import Login from './Login';




import { useCookies } from 'react-cookie';
import Menu from './Menu';

const NoMatch = lazy(() => import('./NoMatch'));
const Fleet = lazy(() => import('./Fleet'));
const Site = lazy(() => import('./Site'));
const Dashboard = lazy(() => import('./Dashboard'));
const Quicksight = lazy(() => import('./Quicksight'));
const Settings = lazy(() => import('./Settings'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./TermsOfUse'));
const Notifications = lazy(() => import('./Notifications'));
const Support = lazy(() => import('./Support'));

import '../base.scss';
import './Layout.scss';

//import FleetAlerts from './FleetAlerts';

import {
  GA_MEASUREMENT_ID
} from '../const';

import ReactGA from "react-ga4";




const App = () => {
  
  const [tokenCookie, setTokenCookie] = useCookies(['token']);

  const sites = useSelector((state) => state.sites.value)
  const idpToken = useSelector((state) => state.idpToken.value)
  const user = useSelector((state) => state.user.value)
  const dispatch = useDispatch()

  const params = useParams();
  const siteId = params.id;

  hotjatInit();
  

  useEffect(() => {
    if(user.full_name && !ReactGA.isInitialized){
      
      analyticsInit(user);
    }

    hotjatIdentifyUser(user);
  }, [user]);
  

  

  return (
    <Router>
      <div>
        <Suspense fallback={<Loader className="loader-full" />}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/site/:id/dashboard" element={<Dashboard />} />
            <Route exact path="/site/:id/fleet" element={<Fleet />} />
            <Route exact path="/site/:id/reports" element={<Quicksight />} />
            <Route exact path="/login" element={<Login />} />
            {/*
            <Route path="settings" element={<Settings />} >
              <Route path="notifications" element={<Notifications />} />
            </Route>
            */}
            <Route exact path="/settings/general" element={<Settings />} />
            <Route exact path="/settings/notifications" element={<Notifications />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/terms-of-use" element={<TermsOfUse />} />
            
            <Route exact path="/support" element={<Support />} />
            
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;