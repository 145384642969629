import React from 'react';
import { useState, useEffect } from "react";
import OfflineFooter from './OfflineFooter';
import OfflineHeader from './OfflineHeader';

import '../base.scss';
import './OfflineLayout.scss';

const OfflineLayout = (props) => {
  let header = false;
  
  if (props.header) {
    header = props.header;
  }

  useEffect(() => {
    
  }, []);

  return (
    <div className='offline-layout'>
      <OfflineHeader hidden={!header}/>
        {props.children}
      <OfflineFooter/>
    </div>
  );
};

export default OfflineLayout;