import { hotjar } from 'react-hotjar'

import {
    HOTJAR_ID,
    HOTJAR_SNIPPET_VERSION
} from '../const';

export const hotjatInit = () => {
    hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);
}

export const hotjatIdentifyUser = (user) => {
    
    if (user.uuid && hotjar.initialized()) {
        hotjar.identify(user.uuid, { 
            'Full Name': user.full_name,
            'First Name': user.given_name,
            'Last Name': user.last_name,
            'Email': user.email,
            'Company': user.company
        });
    }
}
