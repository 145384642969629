import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

let debug = false;
if (process.env.NODE_ENV === 'development') {
  debug = true;
}

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr'],
    ns: 'common',
    defaultNS: false,
    debug: debug,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      //escapeValue: true
    },

    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`
    },

    saveMissing: debug,
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
       console.warn(lng, ns, key, fallbackValue)
    }
  });

export default i18n;
