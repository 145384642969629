import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import { analyticsPageView } from '../features/analyticsLogging';
import { useSelector, useDispatch } from 'react-redux'
import { Link, useParams, NavLink, useNavigate, useLocation } from 'react-router-dom';

import {API_IDP_URI, VIEW_DASHBOARD, VIEW_FLEET, VIEW_REPORT, VIEW_NOTIFICATION} from '../const';


import Layout from './Layout';


import './Home.scss';


const SiteCard = (props) => {
  const { t, i18n } = useTranslation('home')
  const site = props.site
  const userAlerts = useSelector((state) => state.userAlerts.value)
  const [notifications, setNotifications] = useState([])

  let viewDashboard = false;
  let viewFleet = false;
  let viewReport = false;
  let viewNotification = false;
  let link = "";

  viewDashboard = site.actions.indexOf(VIEW_DASHBOARD) !== -1 ? true : false;
  viewFleet = site.actions.indexOf(VIEW_FLEET) !== -1 ? true : false;
  viewReport = site.actions.indexOf(VIEW_REPORT) !== -1 ? true : false;
  viewNotification = site.actions.indexOf(VIEW_NOTIFICATION) !== -1 ? true : false;

  
  if (viewDashboard) {
    link = "/site/"+site.uuid+"/dashboard"
  } else if (viewFleet) {
    link = "/site/"+site.uuid+"/fleet"
  } else if (viewReport) {
    link = "/site/"+site.uuid+"/reports"
  }


  
  useEffect(() => {
    if(userAlerts.length > 0) {
      let temp = userAlerts.filter(e => e.site.uuid === site.uuid)
      setNotifications(temp)
    }
  }, [userAlerts])

  return (
    <NavLink to={link} className="home__card">
      <div className='home__card-title'>
        <p className='home__card-tenant'>{site.tenant.name}</p>
        <h5 className='home__card-name'>{site.name}</h5>
      </div>
      <div className='home__card-notif' hidden={!notifications.length || !viewNotification}>
          
          {!viewFleet && 
            <>
              {notifications.length}&nbsp;
              {notifications.length == 1 &&
                t('notification', { ns: 'home' })
              }
              {notifications.length > 1 &&
                t('notifications', { ns: 'home' })
              }
            </>
          }
          {viewFleet && 
            <span>
              {notifications.length}&nbsp;
              {notifications.length == 1 &&
                t('notification', { ns: 'home' })
              }
              {notifications.length > 1 &&
                t('notifications', { ns: 'home' })
              }
            </span>
            }
      </div>
      
    </NavLink>
  )
}

const Home = () => {
  const { t, i18n } = useTranslation(['menu', 'home']);
  const sites = useSelector((state) => state.sites.value)

  useEffect(() => {
    document.title = t('home', { ns: 'menu' }) + " - " + t('appTitle', { ns: 'menu' })
    analyticsPageView();
  }, []);

  return (
    <Layout>
      <div className='home'>
        <div className='home__header'></div>
        <div className='home__sites'>
          {sites.map((site, index)=>{
            return (
              <SiteCard key={site.uuid} site={site} />
            )
          })}
        </div>
        
      </div>
    </Layout>
  );
};

export default Home;