import React from 'react';
import Icon from '../assets/img/loader-o.svg';
import './Loader.scss';

const Loader = (props) => {

  const hidden = props.hidden;
  const className = props.className;
  
  return (
    <div className={'loader '+className}  hidden={hidden}>

      <svg className="loader__spinner" viewBox="0 0 50 50">
            <defs>
                  <linearGradient id="gradient">
                      <stop offset="0" stopColor="#59b189" stopOpacity="1"/>
                      <stop offset="75%" stopColor="#59b189" stopOpacity="0"/>
                  </linearGradient>
                  
              </defs>
        <circle className="loader__path" cx="25" cy="25" r="20" fill="none"></circle>
      </svg>

      {/*<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>*/}
    </div>
  )
}

export default Loader;