import React from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { setIdpToken } from '../features/idpTokenSlice'
import { setAdminToken } from '../features/adminTokenSlice'
import { setUser } from '../features/userSlice'
import base64 from 'base-64'

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import LanguageDropDown from './LanguageDropdown';

import { analyticsEventLogin, analyticsPageView } from '../features/analyticsLogging';

import OfflineLayout from './OfflineLayout';
import Loader from './Loader';
import { useTranslation } from 'react-i18next';


import sha256 from 'crypto-js/sha256';
import HmacSHA256 from 'crypto-js/hmac-sha256'
import { useState, useEffect } from "react";
import { 
  IDP_TOKEN_URL,
  ADMIN_TOKEN_URL,
  IDP_API_CLIENT_ID,
  IDP_API_CLIENT_SECRET,
  ADMIN_API_CLIENT_ID,
  ADMIN_API_CLIENT_SECRET,
  CODE_VERIFIER,
  API_IDP_URI
} from '../const';

import './Login.scss';
//import Logo from '../assets/img/logo_ugowork_color.svg';
import Logo from '../assets/img/logo_ugopilot_by_ugowork_color.svg';



import { useCookies } from 'react-cookie';



const Login = () => {
  const { t, i18n } = useTranslation(['login', 'menu']);
  const navigate = useNavigate();
  const [idpTokenCookie, setIdpTokenCookie] = useCookies(['idpToken']);
  const [adminTokenCookie, setAdminTokenCookie] = useCookies(['adminToken']);
  const [rememberMeCookie, setRememberMeCookie, deleteRememberMeCookie] = useCookies(['rememberMe']);
  

  const [isLoginPending, setLoginPending] = useState(false)
  const [isLoginSucces, setLoginSucces] = useState(false)
  const codeChallenge = "" + sha256(CODE_VERIFIER);

  const user = useSelector((state) => state.user.value)
  const idpToken = useSelector((state) => state.idpToken.value)
  const adminToken = useSelector((state) => state.adminToken.value)
  const dispatch = useDispatch()

  let location = useLocation();

  
  const initialValues = {
    email: "",
    saveEmail: false,
    password: "",
    newPassword: "",
    confirmNewPassword: "",
    error: "",
    newPasswordError: false,
    confirmNewPasswordError: false,
    rememberMe: false
  };
  const [values, setValues] = useState(initialValues);

  const [validated, setValidated] = useState(false);
  const [isPasswordChangePending, setPasswordChangePending] = useState(false)
  const [isPasswordChangeSubmited, setPasswordChangeSubmited] = useState(false)
  const [isPasswordChangeNeeded, setPasswordChangeNeeded] = useState(false)

  const logIn = () => {
    let searchParams = new URLSearchParams(location?.search)
    const searchParamsObject = {};
    let pathname = "/"

    if(searchParams.has("r")) {
      pathname = searchParams.get("r")
      searchParams.delete("r")
    }

    for (const [key, value] of searchParams.entries()) {
      searchParamsObject[key] = value;
    }
    
    navigate({
      pathname: pathname,
      search: createSearchParams({
          ...searchParamsObject
        }).toString()
    });
  }

  
  useEffect(() => {
    document.title = t('title', { ns: 'login' }) + " - " + t('appTitle', { ns: 'menu' })
    analyticsPageView();

    


  }, []);

  useEffect(() => {
    if (idpToken.access_token && !adminToken.access_token) {
      requestAdminToken();
    } else if (idpToken.access_token && adminToken.access_token) {
      analyticsEventLogin(user);
      switch(idpToken.account_status) {
        case "CONFIRMED":
          //navigate("/");
          logIn();
          break;
        case "FORCE_CHANGE_PASSWORD":
          
          if(values.email != ''){
            setPasswordChangeNeeded(true);
          }
          break;
      }
      setLoginPending(false);
    }
  }, [idpToken.access_token, adminToken.access_token]);

  const requestAdminToken = () => {
    const headers = {
      'X-Ugowork-Signature': base64.encode(HmacSHA256(ADMIN_API_CLIENT_ID+':'+values.email.toLowerCase(), ADMIN_API_CLIENT_SECRET))
    }
    const tokenRequestInfo = {
      grant_type: 'client_credentials',
      client_id: ADMIN_API_CLIENT_ID,
      client_secret: ADMIN_API_CLIENT_SECRET
    };

    axios.post(ADMIN_TOKEN_URL, tokenRequestInfo, {
      headers: headers
    })
    .then(res => {
      var tokenData = {
        access_token: res.data.data.access_token,
        expires: res.data.data.expires,
        token_type: res.data.data.token_type
      }
      dispatch(setAdminToken(tokenData));
      setAdminTokenCookie('adminToken', JSON.stringify(tokenData), { path: '/' });
            
    }).catch((error) => {
      setValues({
        ...values,
        'password': '',
        'error': t('error.default', { ns: 'login' })
      });
    });
  }

  
  useEffect(() => {
    if(rememberMeCookie.rememberMe && rememberMeCookie.rememberMe != '') {
      setValues({
        ...values,
        'email': rememberMeCookie.rememberMe.toLowerCase(),
        'rememberMe': true
      })
    } else {
      setValues({
        ...values,
        'email': '',
        'rememberMe': false
      })
    }
  }, [rememberMeCookie]);

  const requestAuthByPassword = () => {
    const headers = {
      'X-Ugowork-Signature': base64.encode(HmacSHA256(IDP_API_CLIENT_ID+':'+values.email.toLowerCase(), IDP_API_CLIENT_SECRET))
    }
    
    const tokenRequestInfo = {
      grant_type: 'user_password',
      client_id: IDP_API_CLIENT_ID,
      username: values.email.toLowerCase(),
      password: values.password
    };

    axios.post(IDP_TOKEN_URL, tokenRequestInfo, {
        headers: headers
      })
      .then(res => {
        
        var tokenData = {
          access_token: res.data.data.access_token,
          expires: res.data.data.expires,
          token_type: res.data.data.token_type,
          account_status: res.data.data.user.account_status
        }
        dispatch(setUser(res.data.data.user));

        if (i18n.language != res.data.data.user.default_lang) {
          i18n.changeLanguage(res.data.data.user.default_lang);
        }

        switch(res.data.data.user.account_status) {
          case "CONFIRMED":
          case "FORCE_CHANGE_PASSWORD":
            dispatch(setIdpToken(tokenData));
            setIdpTokenCookie('idpToken', JSON.stringify(tokenData), { path: '/' });
            break;
          default:
            setValues({
              ...values,
              'password': '',
              'error': t('error.default', { ns: 'login' })
            });
            
        }
        

      }).catch((error) => {
        setValidated(false)

        let errorMessage = t('error.default', { ns: 'login' })

        if (error.response?.data?.error) {
          
          switch(error.response?.data?.error) {
            case "account_disabled":
              errorMessage = t('error.accountDisabled', { ns: 'login' })
              break;
            case "access_denied":
              errorMessage = t('error.accessDenied', { ns: 'login' })
              break;
            case "data_not_found":
              errorMessage = t('error.dataNotFound', { ns: 'login' })
              break;
            default:
              errorMessage = t('error.default', { ns: 'login' })
          }
        }

        setValues({
          ...values,
          'password': '',
          'error': errorMessage
        });
        setLoginPending(false);
      });
  }

  const requestChangePassword = () => {
    
    if (idpToken.access_token) {
      setLoginPending(true);
      const changePasswordRequestInfo = {
        client_id: IDP_API_CLIENT_ID,
        current_password: values.password,
        new_password: values.newPassword
      };
      const headers = {
          'Authorization': idpToken.token_type + ' ' + idpToken.access_token,
          'X-Ugowork-Signature': base64.encode(HmacSHA256(IDP_API_CLIENT_ID+':'+values.email.toLowerCase(), IDP_API_CLIENT_SECRET))
      }
      
      axios.put(`${API_IDP_URI}v1/me/password`, changePasswordRequestInfo, {
          headers: headers
      })
      .then(res => {
          const newIdpTokenData = {
            ...idpToken,
            'account_status': 'CONFIRMED'
          }
          dispatch(setIdpToken(newIdpTokenData));
          setIdpTokenCookie('idpToken', JSON.stringify(newIdpTokenData), { path: '/' });
          setLoginPending(false);
          //navigate("/");
          logIn()
      }).catch((error) => {
        setValues({
          ...values,
          'newPassword': '',
          'confirmNewPassword': '',
          'error': t('error.default', { ns: 'login' })
        });
        setLoginPending(false);
      });
  }
  }



  const handleChangePasswordSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!values.confirmNewPasswordError && !values.newPasswordError) {
      requestChangePassword();
    }
    setPasswordChangeSubmited(true)
  }

  const handleLoginSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValues({
      ...values,
      'error': ''
    });

    if (form.checkValidity() === true) {
      setLoginPending(true);
      requestAuthByPassword();
      if(values.rememberMe == true) {
        setRememberMeCookie('rememberMe', values.email.toLowerCase(), { path: '/', maxAge: (3600*24*30) });
      }else{
        setRememberMeCookie('rememberMe', '', { path: '/' });
      }
    }

    setValidated(true);
  };

  const handleInputConfirmPassword = (e) => {
    const { name, value } = e.target;
    if(value == values.newPassword) {
      setValues({
        ...values,
        [name]: value,
        confirmNewPasswordError: false,
      });
    } else {
      setValues({
        ...values,
        [name]: value,
        confirmNewPasswordError: true,
      });
    }
  }

  const handleInputChangePassword = (e) => {
    const { name, value } = e.target;
    
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z0-9\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/
    
    if(value.match(regex)!=null
      && value != values.password) {
        
      setValues({
        ...values,
        [name]: value,
        newPasswordError: false,
      });
    } else {
      setValues({
        ...values,
        [name]: value,
        newPasswordError: true,
      });
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setValues({
      ...values,
      [name]: checked,
    });
    
  };

  


  return (
    <OfflineLayout header={false}>
      <div className='login'>
        {<img src={Logo} alt="UgoPilot" className="login__logo"/>}
        {/*
        <h3 hidden={isLoginPending || isPasswordChangeNeeded} className='mb-4 text-white'>{t('title', { ns: 'login' })}</h3>
        */}
        <Loader hidden={!isLoginPending} className="login__loading" />
        

        <Form hidden={isLoginPending || !isPasswordChangeNeeded}  onSubmit={handleChangePasswordSubmit}>
          <p>{t('changePasswordIntro', { ns: 'login' })}</p>
          <div hidden={values.error.length == 0} className='text-danger mb-4'>
            {values.error}
          </div>
          <Form.Group className="mb-4" controlId="newPassword">
            <Form.Label hidden={true}>{t('newPassword', { ns: 'login' })}</Form.Label>
            <Form.Control 
              type="password" 
              placeholder={t('newPassword', { ns: 'login' })} 
              value={values.newPassword}
              onChange={handleInputChangePassword}
              name="newPassword" 
              className="input-password"
              required
              isInvalid={values.newPasswordError && isPasswordChangeSubmited}
            />
            <Form.Control.Feedback type="invalid">
              {t('passwordInvalid', { ns: 'login' })}<br/>
              <u><b>{t('newPasswordInvalid', { ns: 'login' })}</b></u>
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-4" controlId="confirmNewPassword">
            <Form.Label hidden={true}>{t('confirmNewPassword', { ns: 'login' })}</Form.Label>
            <Form.Control 
              type="password" 
              placeholder={t('confirmNewPassword', { ns: 'login' })} 
              value={values.confirmNewPassword}
              onChange={handleInputConfirmPassword}
              name="confirmNewPassword" 
              className="input-password"
              required
              isInvalid={values.confirmNewPasswordError && isPasswordChangeSubmited}
            />
            <Form.Control.Feedback type="invalid">
              {t('confirmNewPasswordInvalid', { ns: 'login' })}
            </Form.Control.Feedback>
          </Form.Group>

          <div className='login__btn-box'>
            <LanguageDropDown className='me-3 icon'/>
            <Button variant="primary" type="submit">
              {t('btnSubmitPassword', { ns: 'login' })}
            </Button>
          </div>
        </Form>

        <Form hidden={isLoginPending || isPasswordChangeNeeded} noValidate validated={validated} onSubmit={handleLoginSubmit}>
          <div hidden={values.error.length == 0} className='text-danger mb-4'>
            {values.error}
          </div>
          <Form.Group className="mb-4" controlId="loginEmail">
            <Form.Label hidden={true}>{t('email', { ns: 'login' })}</Form.Label>
            <Form.Control 
              type="email" 
              placeholder={t('email', { ns: 'login' })}
              value={values.email}
              onChange={handleInputChange}
              name="email" 
              required
              //pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$"
              className="input-email"
            />
            <Form.Control.Feedback type="invalid">
              {t('emailInvalid', { ns: 'login' })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-4" controlId="loginPassword">
            <Form.Label hidden={true}>{t('password', { ns: 'login' })}</Form.Label>
            <Form.Control 
              type="password" 
              placeholder={t('password', { ns: 'login' })} 
              value={values.password}
              onChange={handleInputChange}
              name="password" 
              required
              className="input-password"
              //pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !\x22#$%&\x27()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z0-9\d !\x22#$%&\x27()*+,-./:;<=>?@[\]^_`{|}~]{8,}$"
            />
            <Form.Control.Feedback type="invalid">
              {t('passwordInvalid', { ns: 'login' })}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
            className='prevent-validation'
              type="switch"
              name="rememberMe"
              label={t('saveEmail', { ns: 'login' })} 
              onChange={handleCheckboxChange}
              defaultChecked={rememberMeCookie.rememberMe && rememberMeCookie.rememberMe != ''}
            />
          </Form.Group>

          <div className='login__btn-box'>
            <LanguageDropDown className='icon'/>
            <Button variant="primary" type="submit" className='btn-arrow'>
              {t('btnLabel', { ns: 'login' })}
            </Button>
          </div>
        </Form>

      </div>

      
    </OfflineLayout>
  );
};

export default Login;