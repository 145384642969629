
//let tempClientUrl;
//let tempPortalUrl;
let tempApiIdpUri;
let tempApiAdminUri;
let tempGAMeasurementId;

if (process.env.REACT_APP_BUILD_ENV === 'prod') {
    //tempClientUrl = 'https://ugopilot.ugowork.com/';
    //tempPortalUrl = 'https://idp.staging.ugowork.com/';
    //tempApiIdpUri = 'https://idp.api.ugowork.com/';
    //tempApiAdminUri = 'https://admin.api.ugowork.com/';
    tempGAMeasurementId = 'G-J0L7NNBHZX';

} else if (process.env.REACT_APP_BUILD_ENV === 'staging') {
        
    //tempClientUrl = 'https://customer.staging.ugowork.com/';
    //tempPortalUrl = 'https://idp.staging.ugowork.com/';
    //tempApiIdpUri = 'https://idp-api.staging.ugowork.com/';
    //tempApiAdminUri = 'https://admin-api.staging.ugowork.com/';
    tempGAMeasurementId = 'G-J0L7NNBHZX';

} else if (process.env.REACT_APP_BUILD_ENV === 'test') {

    //tempClientUrl = 'https://customer.test.ugowork.com/';
    //tempPortalUrl = 'https://idp.test.ugowork.com/';
    //tempApiIdpUri = 'https://idp-api.test.ugowork.com/';
    //tempApiAdminUri = 'https://admin-api.test.ugowork.com/';
    tempGAMeasurementId = 'G-C1YEKJ6K94';

} else if (process.env.REACT_APP_BUILD_ENV === 'demo') {

    //tempClientUrl = 'https://customer.demo.ugowork.com/';
    //tempPortalUrl = 'https://idp.demo.ugowork.com/';
    //tempApiIdpUri = 'https://idp-api.demo.ugowork.com/';
    //tempApiAdminUri = 'https://admin-api.demo.ugowork.com/';
    tempGAMeasurementId = 'G-C1YEKJ6K94';

} else {

    //tempClientUrl = 'https://client.ugowork.xyz:3000/';
    //tempPortalUrl = 'https://idp.test.ugowork.com/';
    //tempApiIdpUri = 'https://idp-api.test.ugowork.com/';
    //tempApiAdminUri = 'https://admin-api.test.ugowork.com/';

    tempGAMeasurementId = 'G-C1YEKJ6K94';

}


//export const CLIENT_URL = tempClientUrl;
//export const PORTAL_IDP_URL = tempPortalUrl;
//export const API_IDP_URI = tempApiIdpUri;
//export const API_ADMIN_URI = tempApiAdminUri;
export const API_IDP_URI = process.env.REACT_APP_IDP_API_URI;
export const API_ADMIN_URI = process.env.REACT_APP_ADMIN_API_URI;

export const GA_MEASUREMENT_ID = tempGAMeasurementId;

//export const AUTHORIZATION_URL = PORTAL_IDP_URL + 'v1/oauth2/authorize';

//export const REDIRECT_URI = CLIENT_URL + "oauth-callback";

export const CODE_VERIFIER = "Did you ever hear the tragedy of Darth Plagueis The Wise?";

export const IDP_TOKEN_URL = API_IDP_URI + 'v1/oauth2/token';
export const IDP_API_CLIENT_ID = process.env.REACT_APP_IDP_API_CLIENT_ID;
export const IDP_API_CLIENT_SECRET = process.env.REACT_APP_IDP_API_CLIENT_SECRET;

export const ADMIN_TOKEN_URL = API_ADMIN_URI + 'v1/oauth2/token';
export const ADMIN_API_CLIENT_ID = process.env.REACT_APP_ADMIN_API_CLIENT_ID;
export const ADMIN_API_CLIENT_SECRET = process.env.REACT_APP_ADMIN_API_CLIENT_SECRET;

export const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;
export const HOTJAR_SNIPPET_VERSION = process.env.REACT_APP_HOTJAR_SNIPPET_VERSION;

export const REFRESH_RATE = 60000;


export const VIEW_DASHBOARD = 'ugowork-customer-portal:dashboard:ViewDashboard';
export const VIEW_FLEET = 'ugowork-customer-portal:fleet:ViewFleet';
export const VIEW_REPORT = 'ugowork-customer-portal:reporting:ViewReports';
export const VIEW_NOTIFICATION = 'ugowork-customer-portal:notifications:ManageNotifications';

//Epoch timestamp: 1695830400
//Timestamp in milliseconds: 1695830400000
//Date and time (GMT): Wednesday 27 September 2023 16:00:00
//Date and time (your time zone): mercredi 27 septembre 2023 12:00:00 GMT-04:00
//export const POLICY_TIMESTAMP = 1695830400;


//Epoch timestamp: 1702486713
//Timestamp in milliseconds: 1702486713000
//Date and time (GMT): Wednesday 13 December 2023 16:58:33
//Date and time (your time zone): mercredi 13 décembre 2023 11:58:33 GMT-05:00
//export const POLICY_TIMESTAMP = 1702486713;

//Epoch timestamp: 1714406400
//Timestamp in milliseconds: 1714406400000
//Date and time (GMT): Monday 29 April 2024 16:00:00
//Date and time (your time zone): lundi 29 avril 2024 12:00:00 GMT-04:00
export const POLICY_TIMESTAMP = 1714406400;