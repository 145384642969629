import { createSlice } from '@reduxjs/toolkit'

export const fleetAlertsSlice = createSlice({
  name: 'fleetAlerts',
  initialState: {
    value: [],
    show: {}
  },
  reducers: {
    setFleetAlerts: (state, action) => {
        state.value = action.payload
    },
    setShowFleetAlerts: (state, action) => {
      state.show = action.payload
    },
  },
})

export const { setFleetAlerts, setShowFleetAlerts } = fleetAlertsSlice.actions


export default fleetAlertsSlice.reducer