import { createSlice } from '@reduxjs/toolkit'


export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    value: {},
  },
  reducers: {
    setSettings: (state, action) => {
        state.value = action.payload
    },
    
  },
})


export const { setSettings } = settingsSlice.actions

export default settingsSlice.reducer