import React from 'react';
import axios from 'axios';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'

import { setSettings } from '../features/settingsSlice'

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import Loader from './Loader';
import ErrorInline from './ErrorInline';

import {API_IDP_URI, POLICY_TIMESTAMP} from '../const';

import './AcceptAgreementModal.scss';

const AcceptAgreementModal = (props) => {
  const idpToken = useSelector((state) => state.idpToken.value)
  const { t, i18n } = useTranslation(['acceptAgreementModal']);
  const settings = useSelector((state) => state.settings.value);
  const [showModal, setShowModal] = useState(false);
  const [isAcceptDisabled, setAcceptDisabled] = useState(true);
  const [dataPending, setDataPending] = useState(false);
  const [dataError, setDataError] = useState(false);
  const dispatch = useDispatch()

  const handleSave = (e) => {
    setDataPending(true)
    setDataError(false)
    const headers = {
      'Authorization': idpToken.token_type + ' ' + idpToken.access_token
    }
    const payload = {
      "name": "policy_accepted_at",
      "value": moment().unix()
    }
    
    axios.post(`${API_IDP_URI}v1/me/settings`, payload, {
      headers: headers
    })
    .then(res => {
      
      dispatch(setSettings({
        ...settings,
        policy_accepted_at: res.data.data.value
      }))
      //setDataPending(false)
      setDataError(false)
      setShowModal(false)
    }).catch((error) => {
      setDataError(true)
      setDataPending(false)
    });
  }

  useEffect(() => {
    if (Object.keys(settings).length !== 0){

      var policyTimestamp = moment.unix(POLICY_TIMESTAMP);
      var acceptedTimestamp = moment.unix(settings.policy_accepted_at)

      if(!settings.policy_accepted_at || (policyTimestamp > acceptedTimestamp) || (policyTimestamp < acceptedTimestamp && acceptedTimestamp.diff(policyTimestamp, 'months') > 12)) {
        setShowModal(true)
      }
    }
    
  }, [settings]);

  return (
    <Modal show={showModal} backdrop="static" className="accept-agreement-modal">
      <Modal.Header>
        <Modal.Title>{t('title', { ns: 'acceptAgreementModal' })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loader className='accept-agreement-modal__loader'
            hidden={!dataPending} 
        />
        <ErrorInline 
            className='accept-agreement-modal__error'
            hidden={!dataError}
            onClick={() => handleSave()}
        />
        <div hidden={dataError || dataPending}>
          <p>{t('text', { ns: 'acceptAgreementModal' })}</p>
          <Form.Check
            type='checkbox'
            id={`default-checkbox`}
            onChange={(e) => setAcceptDisabled(!e.target.checked)}
            label = {(<Trans
                        i18nKey="checkboxText"
                        ns='acceptAgreementModal'
                        components={{
                            linkPrivacy: <a href="/privacy-policy" target="_blank" />,
                            linkTerms: <a href="/terms-of-use" target="_blank" />
                        }}
                      />)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={isAcceptDisabled} className='btn-submit' variant="primary" onClick={handleSave} hidden={dataError || dataPending}>
          {t('accept', { ns: 'acceptAgreementModal' })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AcceptAgreementModal;