import { createSlice } from '@reduxjs/toolkit'

export const adminTokenSlice = createSlice({
  name: 'adminToken',
  initialState: {
    value: {},
  },
  reducers: {
    setAdminToken: (state, action) => {
        state.value = action.payload
    },
  },
})


export const { setAdminToken } = adminTokenSlice.actions

export default adminTokenSlice.reducer