import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';

import './ErrorInline.scss';

const ErrorInline = (props) => {

  const { t, i18n } = useTranslation('errorInline');
  const hidden = props.hidden;
  const className = props.className;
  
  return (
    <div className={'inline-error '+className}  hidden={hidden}>
      <div className='inline-error__container'>
        <h3 className='inline-error__title'>
          <svg className='alert-header-title' width='1600' height='1600' viewBox='0 0 1200 1200' xmlns='http://www.w3.org/2000/svg'><g fill='#fff'><path d='M1150.5 891.95 728.2 164.33c-26.773-45.398-75.551-73.258-128.25-73.258S498.47 118.935 471.7 164.33L49.49 891.95c-25.918 44.258-25.918 99.059 0 143.32 26.324 45.898 75.344 74.051 128.25 73.652h844.52c52.984.473 102.1-27.688 128.46-73.652 25.855-44.297 25.777-99.102-.203-143.32zm-70.355 102.68v.285a65.833 65.833 0 0 1-57.898 32.57h-844.52a65.837 65.837 0 0 1-57.895-32.855 60.289 60.289 0 0 1 0-61.805l422.38-727.79a67.422 67.422 0 0 1 115.55 0l422.26 727.78a60.315 60.315 0 0 1 .121 61.81z'/><path d='M599.95 335.33c-22.484 0-40.715 18.23-40.715 40.715v325.72c0 22.484 18.23 40.715 40.715 40.715s40.715-18.227 40.715-40.715v-325.72c0-22.488-18.23-40.719-40.715-40.719zM599.95 823.92c-22.484 0-40.715 18.227-40.715 40.715v40.715c0 22.484 18.23 40.715 40.715 40.715s40.715-18.227 40.715-40.715v-40.715c0-22.488-18.23-40.715-40.715-40.715z'/></g></svg>
          {t('title', { ns: 'errorInline' })}
        </h3>
        <p>{t('text', { ns: 'errorInline' })}</p>
        <Button 
          variant="outline-light" 
          type="button" 
          className='text-white2 inline-error__btn'
          onClick={() => props.onClick()}  
        >
          <svg x="0px" y="0px" width="14" height="14" className='spin'
          viewBox="0 0 492.883 492.883" enableBackground="new 0 0 492.883 492.883">
            <g>
              <g>
                <path d="M122.941,374.241c-20.1-18.1-34.6-39.8-44.1-63.1c-25.2-61.8-13.4-135.3,35.8-186l45.4,45.4c2.5,2.5,7,0.7,7.6-3
                  l24.8-162.3c0.4-2.7-1.9-5-4.6-4.6l-162.4,24.8c-3.7,0.6-5.5,5.1-3,7.6l45.5,45.5c-75.1,76.8-87.9,192-38.6,282
                  c14.8,27.1,35.3,51.9,61.4,72.7c44.4,35.3,99,52.2,153.2,51.1l10.2-66.7C207.441,421.641,159.441,407.241,122.941,374.241z"/>
                <path d="M424.941,414.341c75.1-76.8,87.9-192,38.6-282c-14.8-27.1-35.3-51.9-61.4-72.7c-44.4-35.3-99-52.2-153.2-51.1l-10.2,66.7
                  c46.6-4,94.7,10.4,131.2,43.4c20.1,18.1,34.6,39.8,44.1,63.1c25.2,61.8,13.4,135.3-35.8,186l-45.4-45.4c-2.5-2.5-7-0.7-7.6,3
                  l-24.8,162.3c-0.4,2.7,1.9,5,4.6,4.6l162.4-24.8c3.7-0.6,5.4-5.1,3-7.6L424.941,414.341z"/>
              </g>
            </g>
          </svg>
          {t('btn', { ns: 'errorInline' })}
        </Button>
      </div>
    </div>
  )
}

export default ErrorInline;