import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageDropDown from './LanguageDropdown';
import Moment from 'react-moment';

import './OfflineFooter.scss';

const OfflineFooter = (props) => {
  const { t, i18n } = useTranslation(['menu', 'common']);


  return (
    <footer className="offline-footer"> 
      <Link to={"/privacy-policy"} className="nav-link offline-footer__link"> 
        {t('privacyPolicy', { ns: 'menu' })} 
      </Link> 
      <Link to={"/terms-of-use"} className="nav-link offline-footer__link"> 
        {t('termsOfUse', { ns: 'menu' })} 
      </Link> 
      <LanguageDropDown className='mx-2' hidden={true}/>
      <p className='offline-footer__copyright'>© <Moment format="YYYY"/> {t('copyright', { ns: 'common' })} </p>
    </footer> 
  );
};

export default OfflineFooter;