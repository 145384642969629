import React from 'react';
import axios from 'axios';
import { useState, useEffect } from "react";
import { Link, useParams, NavLink, useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import Menu from './Menu';
import MenuHeader from './MenuHeader';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

import AcceptAgreementModal from './AcceptAgreementModal';

import {API_IDP_URI} from '../const';

import { useSelector, useDispatch } from 'react-redux'
import { setSites } from '../features/sitesSlice'
import { setIdpToken } from '../features/idpTokenSlice'
import { setAdminToken } from '../features/adminTokenSlice'
import { setAccessPolicy } from '../features/accessPolicySlice'
import { setUser } from '../features/userSlice'
import { setSettings} from '../features/settingsSlice'
import { useCookies } from 'react-cookie';

import { Scrollbar } from 'react-scrollbars-custom';

import '../base.scss';
import './Layout.scss';



const Layout = ({ children }) => {

  const idpToken = useSelector((state) => state.idpToken.value)
  const adminToken = useSelector((state) => state.adminToken.value)
  const sites = useSelector((state) => state.sites.value)
  const user = useSelector((state) => state.user.value)
  const accessPolicy = useSelector((state) => state.accessPolicy.value)
  const settings = useSelector((state) => state.settings.value)
  
  const dispatch = useDispatch()
  const navigate = useNavigate();
  let location = useLocation();
  const { t, i18n } = useTranslation(['common']);

  const [idpTokenCookie, setIdpTokenCookie] = useCookies(['idpToken']);
  const [adminTokenCookie, setAdminTokenCookie] = useCookies(['adminToken']);

  const logOut = () => {
    let searchParams = new URLSearchParams(location?.search)
    const searchParamsObject = {};

    for (const [key, value] of searchParams.entries()) {
      searchParamsObject[key] = value;
    }

    if (location?.pathname && location?.pathname != "/") {
      
      navigate({
        pathname: "/login",
        search: createSearchParams({
            ...searchParamsObject,
            r: location?.pathname,
          }).toString()
      });
      
    } else  {
      navigate("/login");
    }
  }

  const headers = {
    'Authorization': idpToken.token_type + ' ' + idpToken.access_token
  }

  const setDefaultTimezone = () => {
    //Intl.DateTimeFormat().resolvedOptions().timeZone
    const payload = {
      "name": "timezone",
      "value": Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    axios.post(`${API_IDP_URI}v1/me/settings`, payload, {
      headers: headers
    })
    .then(res => {
      
    });
  }
  
  useEffect(() => {
    if (!adminToken.access_token) {
      if(adminTokenCookie.adminToken){
        dispatch(setAdminToken(adminTokenCookie.adminToken));
      } else {
        //navigate("/login");
        logOut()
      }
    }

    if (adminTokenCookie.adminToken && idpTokenCookie.adminToken.expires > 0) {
      const interval = setInterval(() => {
        setIdpTokenCookie('adminToken', '', { path: '/' });
        dispatch(setAdminToken({}));
        //navigate("/login");
        logOut()
      }, (adminTokenCookie.adminToken.expires*1000) - Date.now());
      return () => clearInterval(interval);
    }
  }, [adminToken]);

  useEffect(() => {
    if (idpToken.access_token && idpToken.account_status == "CONFIRMED") {
      
        axios.get(`${API_IDP_URI}v1/me`, {
            headers: headers
        })
        .then(res => {
            if(res.data.data){
                dispatch(setUser(res.data.data.user));
                dispatch(setAccessPolicy(res.data.data.access_policy.Statement));
                dispatch(setSites(res.data.data.access_policy.Statement));
                
                const minimalSettings = {
                  "notifications_schedule": [
                      {
                        "start_time": "09:00",
                        "end_time": "17:00",
                        "day_of_week": [],
                        "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
                      }
                  ],
                  "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
                }

                if (!res.data.data.settings){
                  dispatch(setSettings(minimalSettings))
                  
                } else {
                  dispatch(setSettings({...minimalSettings,...res.data.data.settings}))

                  

                }
                if (!res.data.data.settings?.timezone){
                  setDefaultTimezone()
                }
                
                if (i18n.language != res.data.data.user.default_lang) {
                  i18n.changeLanguage(res.data.data.user.default_lang);
                }
            }
        });


      
    } else if (idpToken.access_token && !idpToken.account_status == "CONFIRMED"){
      //navigate("/login");
      logOut()
    } else if (!idpToken.access_token) {
      if(idpTokenCookie.idpToken){
        dispatch(setIdpToken(idpTokenCookie.idpToken));
      } else {
        //navigate("/login");
        logOut()
      }
    }

    //TODO a refaire
    if (idpTokenCookie.idpToken && idpTokenCookie.idpToken.expires > 0) {
      const interval = setInterval(() => {
        setIdpTokenCookie('idpToken', '', { path: '/' });
        dispatch(setIdpToken({}));
        //navigate("/login");
        logOut()
      }, (idpTokenCookie.idpToken.expires*1000) - Date.now());
      return () => clearInterval(interval);
    }
  }, [idpToken]);

  useEffect(() => {
    
  }, []);

  

  useEffect(() => {
    if (location?.pathname == '/' && sites.length == 1) {
      navigate("/site/"+sites[0].uuid+"/dashboard")

    }
    
  }, [location, sites]);

  return (
    <div className='layout'>
      <Menu/>
      <div className="layout__container">
        <div className='layout__header'>
          <MenuHeader />
        </div>
        <div className='layout__main'>
          
            <Scrollbar>
            <div className='layout__content'>
            {children}
            </div>
            <p className='layout__copyright'>© <Moment format="YYYY"/> {t('copyright', { ns: 'common' })} </p>
            </Scrollbar>
        </div>
      </div>
      <AcceptAgreementModal />
    </div>
  );
};

export default Layout;