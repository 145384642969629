// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu-header{align-items:center;display:flex;justify-content:flex-end;padding:.75rem 1.5rem}.menu-header__left{margin-right:auto}.menu-header__right{align-items:center;display:flex}.menu-header__right>div{margin-left:.75rem}", "",{"version":3,"sources":["webpack://./src/components/MenuHeader.scss"],"names":[],"mappings":"AAMA,aAKI,kBAAA,CAJA,YAAA,CAGA,wBAAA,CAFA,qBAGA,CAEA,mBACI,iBAAA,CAGJ,oBAEI,kBAAA,CADA,YACA,CACA,wBACI,kBAAA","sourcesContent":["@import \"../variables\";\r\n@import \"~bootstrap/scss/functions\";\r\n@import \"~bootstrap/scss/variables\";\r\n@import \"~bootstrap/scss/mixins\";\r\n\r\n\r\n.menu-header {\r\n    display: flex;\r\n    padding: 0.75rem 1.5rem;\r\n    //justify-content: space-between;\r\n    justify-content: flex-end;\r\n    align-items: center;\r\n\r\n    &__left {\r\n        margin-right: auto;\r\n    }\r\n\r\n    &__right {\r\n        display: flex;\r\n        align-items: center;\r\n        &>div {\r\n            margin-left: 0.75rem;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var menuHeader = "menu-header";
export var menuHeaderLeft = "menu-header__left";
export var menuHeaderRight = "menu-header__right";
export default ___CSS_LOADER_EXPORT___;
