import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
//import Logo from '../assets/img/logo_ugowork_color.svg';
import Logo from '../assets/img/logo_ugopilot_by_ugowork_color.svg';

import './OfflineHeader.scss';

const OfflineHeader = (props) => {
  const navigate = useNavigate();

  let hidden = true;

  if (props.hidden != undefined) {
    hidden = props.hidden;
  }

	const goBack = (event) => {
    event.preventDefault();
    event.stopPropagation();
		navigate(-1);
	}

  return (
    <div className='offline-header' hidden={hidden}>
      {/*<a href="#" onClick={goBack} className='offline-header__back'>
        <svg width="1200pt" height="1200pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
          <path d="m438 636h452.4v-72h-452.4l204-204h-102l-240 240 240 240h102z"/>
        </svg>
  </a>*/}
      <Link to="/" className="offline-header__logo">
          <img src={Logo} alt="UgoPilot"/>
      </Link>
    </div>
  );
};

export default OfflineHeader;